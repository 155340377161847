@mixin font-general($font-style, $font-weight, $font-size, $line-height, $color){
  font-style: $font-style;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
  color: $color;
}

.hide-column{
  display: block !important;
}

#form-offer{
  .ant-input-number{
    width: 100%;
  }

  .text-danger-mess{
    color: #ff4d4f;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }

  .ant-picker-range{
    width: 100%;
  }
}

.btn-location{
  padding: 0;
  height: 26px;
  span {
    cursor: pointer;
    width: 20px;
  }
}

.emulator-mobile{
  width: 360px;
  height: 740px;
  overflow-y: auto;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  //border-radius: 15px;
  background: #f3f6fa;
  .header{
    width: 100%;
    .background-banner{
      //border-top-left-radius: 15px;
      //border-top-right-radius: 15px;
      height: 210px;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 1rem 10px 0;

      .header-mobile{
        height: 100%;

        .avatar-brand{
          position: relative;
          top: 2rem;
          box-sizing: border-box;
          width: 64px;
          height: 64px;
          border: 2px solid #FFFFFF;
          border-radius: 100%;
          background-size: contain;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.09));
        }
      }
    }

    .title-offer{
      margin-top: 3rem;
      opacity: 0.89;
      padding-bottom: 10px;
      @include font-general( normal, 600, 18px, 24px, #000000);
      border-bottom: 2px solid #F5F5F5;
    }

    .title-expired{
      @include font-general(normal, 400, 14px, 17px, #000000);
      opacity: 0.9;
      padding: 1rem;
    }
  }

  .offer-content{
    padding: 1rem 1rem 5px;
  }

  .offer-footer{
    padding: 1rem;
  }

  .custom-title{
    @include font-general(normal, 600, 1rem, 20px, #1E2153);
    opacity: 0.9;
  }
}